import React from "react";
import { Container, Typography, Grid, Button } from "@mui/material";
import { RightGradientWhite } from "../../Components/BgEffect";
import Fade from "react-reveal/Fade";
import ABOUT_IMAGE from "../../Assets/aboutimg.png";

const About = () => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <RightGradientWhite />
      </div>
      <Container
        id="about"
        maxWidth="lg"
        sx={{ margin: "100px auto", textAlign: { md: "left", xs: "center" } }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid item xl="5" md="5" xs="12">
            <Fade left>
              <Typography
                fontSize="40px"
                fontWeight="700"
                lineHeight="53px"
                color="inherit"
              >
                About Us
              </Typography>
              <br />
              <br />
              <Typography
                fontSize="20px"
                fontWeight="400"
                fontFamily="Heebo"
                lineHeight="29px"
                color="inherit"
              >
                Crypto Convoy represents a collection of 1500 unique utility
                based bulls and bears NFT which are stored as ERC-721 tokens on
                the Ethereum blockchain and hosted on an interplanetary file
                system (IPFS).
                <br />
                <br />
                Our goal is to create a community of like-minded crypto peoples
                where they will get share their knowledge, talk to crypto
                analysts and create a social finance based ecosystem in this
                crypto world.
              </Typography>
              <br />
              <br />

              <a
                href="/mint"
                // target="_blank"
                rel="noreferrer "
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    background:
                      "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
                    boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
                    borderRadius: "20px",
                    padding: "10px 50px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Mint Now
                </Button>
              </a>

              <br />
              <br />
            </Fade>
          </Grid>
          <Grid item xl="5" md="5" xs="12">
            <Fade bottom>
              <img src={ABOUT_IMAGE} alt="aboutimg" className="image" />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;
