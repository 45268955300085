import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import Tilt from "react-tilt";
import { useStyles } from "./Styles";
import Fade from "react-reveal/Fade";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IMG1 from "../../Assets/sell (1).png";
import IMG2 from "../../Assets/sell (2).png";
import IMG3 from "../../Assets/sell (3).png";
import IMG4 from "../../Assets/sell (4).png";
import IMG5 from "../../Assets/sell (5).png";
import IMGSELL1 from "../../Assets/sell1.png";
import IMGUP from "../../Assets/Up.png";
import PLUSIMG from "../../Assets/Plus.png";
import ETHIMG from "../../Assets/etherium.png";
import PREFRENCEIMG from "../../Assets/Preferences.png";

const CreateAndSell = () => {
  const classes = useStyles();
  return (
    <>
      <Container id="commnunity" maxWidth="lg" sx={{ margin: "100px auto" }}>
        <Box>
          <Typography
            gutterBottom
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
            color="#9C00E8"
            lineHeight="24px"
          >
            How to
          </Typography>
          <Typography
            fontSize="38px"
            fontWeight="bold"
            textAlign="center"
            color="inherit"
            fontFamily="Heebo"
            lineHeight="56px"
          >
            Participate in the mint sale?
          </Typography>
        </Box>
        <br />
        <br />
        <Box
          display="flex"
          flexWrap="wrap"
          gap="50px"
          my="30px"
          sx={{ justifyContent: { md: "space-between", xs: "center" } }}
        >
          {CreateData.map((item, i) => (
            <Fade bottom>
              <Box className={classes.card_create}>
                <Tilt className="Tilt" options={{ max: 25 }}>
                  {item.img}
                </Tilt>
                <Typography
                  px="20px"
                  fontSize="22px"
                  fontWeight="700"
                  fontFamily="Heebo"
                  color="inherit"
                  lineHeight="32px"
                >
                  {item.title}
                </Typography>
                <Typography
                  px="20px"
                  fontSize="20px"
                  fontWeight="300"
                  fontFamily="Heebo"
                  color="inherit"
                  lineHeight="29px"
                >
                  {/* {item.title} */}
                </Typography>
              </Box>
            </Fade>
          ))}
        </Box>
        <br />
        <br />
        <Box textAlign="center">
        <a
                href="/mint"
                // target="_blank"
                rel="noreferrer "
                style={{ textDecoration: "none" }}
              >
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            sx={{
              borderRadius: "20px",
              padding: "10px 30px",
              marginRight: "20px",
            }}
          >
            Mint Now
          </Button>

          </a>
          {/* <Button variant="outlined"
                    sx={{ borderRadius: "20px", padding: "10px 30px", color: '#0D0D0D', borderColor: '#0D0D0D' }}>
                    Watch Video
                </Button> */}
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ marginBottom: "100px", display: "none" }}>
        <Box>
          <Typography
            gutterBottom
            fontSize="18px"
            fontWeight="bold"
            textAlign="center"
            color="#9C00E8"
            lineHeight="24px"
          >
            How to be a creator
          </Typography>
          <Typography
            fontSize="38px"
            fontWeight="bold"
            textAlign="center"
            color="inherit"
            fontFamily="Heebo"
            lineHeight="56px"
          >
            Create and Sell your NFTs
          </Typography>
        </Box>
        <br />
        <br />
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{ gap: { md: "50px", xs: "20px" } }}
          my="30px"
          justifyContent="space-between"
        >
          {SellData.map((item, i) => (
            <Fade bottom>
              <Tilt className="Tilt" options={{ max: 25 }}>
                <Box className={classes.card_sell}>
                  <Box
                    display="flex"
                    alignItems="center"
                    px="10px"
                    sx={{ gap: { md: "20px", xs: "10px" } }}
                  >
                    {item.img}
                    <Box
                      display="flex"
                      color="inherit"
                      alignItems="center"
                      gap="100px"
                    >
                      <div>
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          fontFamily="Heebo"
                          color="inherit"
                          lineHeight="26px"
                        >
                          Space Runner
                        </Typography>
                        <br />
                        <Box display="flex" alignItems="center" gap="10px">
                          <img src={ETHIMG} alt="nftcardimg" />
                          <Typography
                            fontSize="14px"
                            fontWeight="normal"
                            fontFamily="Heebo"
                            color="#8C8C8C"
                            lineHeight="26px"
                          >
                            @toritoriko
                          </Typography>
                        </Box>
                      </div>
                      <MoreHorizIcon />
                    </Box>
                  </Box>
                </Box>
              </Tilt>
            </Fade>
          ))}
        </Box>
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          sx={{
            borderRadius: "20px",
            padding: "10px 30px",
            margin: " 0 auto",
            display: "block",
          }}
        >
          View Ranking
        </Button>
      </Container>
    </>
  );
};

export default CreateAndSell;

const CreateData = [
  {
    title: "Visit the mint page on 13th March 2022 11:30 am UTC.",
    img: <img src={PLUSIMG} alt="img_" width="100px" />,
  },
  {
    title: "Connect your wallet through metamask or any preferred wallet.",
    img: <img src={IMGUP} alt="img_" width="100px" />,
  },
  {
    title:
      "Select the quantity and click on mint button, make sure you have 0.1 ETH + GAS in your wallet.",
    img: <img src={PREFRENCEIMG} alt="img_" width="100px" />,
  },
];
const SellData = [
  {
    title: "",
    img: <img src={IMGSELL1} alt="img_" />,
  },
  {
    title: "",
    img: <img src={IMG1} alt="img_" />,
  },
  {
    title: "",
    img: <img src={IMG2} alt="img_" />,
  },
  {
    title: "",
    img: <img src={IMG3} alt="img_" />,
  },
  {
    title: "",
    img: <img src={IMG4} alt="img_" />,
  },
  {
    title: "",
    img: <img src={IMG5} alt="img_" />,
  },
];
