import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    boxex: {
        // "&:hover": {
        // },
        // [theme.breakpoints.down(500)]: {
        // },
    },
    card_create: {
        width: "336px",
        height: "383px",
        background: "rgba( 230, 230, 230, 0.25 )",
        backdropFilter: "blur( 4px )",
        WebkitBackdropFilter: "blur( 4px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        // background: "#fff",
        borderRadius: "21px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',  
        transition: "1s",
        "&:hover": {
            border: "1px solid #9C00E8",
            boxSizing: "border-box",
            boxShadow: "0px 4px 4px #9C00E8",
        },
    },
    btn: {
        background:
            "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
        boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
        fontSize: "18px",
        fontWeight: "bold",
    },
    card_sell: {
        width: "371px",
        height: "120px",
        bgcolor: 'background.default',
        // background: "#fff",
        borderRadius: "22px",
        border: "0.5px solid #D8D8D8",
        display: 'flex',
        alignItems: 'center',
        "&:hover": {
            transition: "1s",
            border: "1px solid #9C00E8",
            boxSizing: "border-box",
            boxShadow: "0px 4px 4px #9C00E8",
        },
        [theme.breakpoints.down(500)]: {
            width: "100%",
        },
    }
}));
