import React from "react";
import { Container, Typography, Grid, Button } from "@mui/material";
import Fade from "react-reveal/Fade";
import ArtGalleryIMG from "../../Assets/artgallery.png";

const ArtGallery = () => {
  return (
    <div>
      <Container
        id="utilites"
        maxWidth="lg"
        sx={{ margin: "100px auto", textAlign: { md: "left", xs: "center" } }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="stretch"
          flexWrap="wrap-reverse"
        >
          <Grid item xl="5" md="5" xs="12">
            <Fade left>
              <img src={ArtGalleryIMG} alt="artgallery" className="image" />
            </Fade>
          </Grid>
          <Grid item xl="5" md="5" xs="12">
            <Fade bottom>
              <Typography
                fontSize="40px"
                fontWeight="700"
                lineHeight="53px"
                color="inherit"
              >
                NFT utilities
              </Typography>
              <br />
              <br />
              <Typography
                fontSize="20px"
                fontWeight="400"
                fontFamily="Heebo"
                lineHeight="29px"
                color="inherit"
              >
                By collecting our NFTs, holders will be eligible to be a part of
                a private group of like-minded peoples, where they can talk with
                some good crypto analysts, get a lot of social investment
                opportunities and get into our metaverse club. Other than this
                we will reveal a lot of new features and exclusive rewards for
                our NFT holders.
              </Typography>
              <br />
              <br />

              <a
                href="/mint"
                // target="_blank"
                rel="noreferrer "
                style={{ textDecoration: "none" }}
              >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background:
                    "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
                  boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
                  borderRadius: "20px",
                  padding: "10px 50px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Mint Now
              </Button>

              </a>
              <br />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ArtGallery;
