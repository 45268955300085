import About from "../Views/Sections/About/About";
import Home from "../Views/Sections/Home/Home";
import ArtGallery from "../Views/Sections/ArtGallery/ArtGallery";
import CreateAndSell from "../Views/Sections/Create_sell/CreateAndSell";
import Faqs from "../Views/Sections/Faqs/Faqs";
import Roadmap from "../Views/Sections/Roadmap/Roadmap";

const HomePage = () => {
  return (
    <>
      <Home />
      <About />
      <ArtGallery />
      <Roadmap />
      <CreateAndSell />
      <Faqs />
    </>
  );
};

export default HomePage;
