import { Box } from '@mui/material'
import SHADOW from "../Assets/shadow.png"
import WHITE from "../Assets/white.png"
export function LeftGradientBlue() {
    return (
        <>
            <Box sx={{
                position: 'absolute', left: '0', display:'none'
            }}>
                <img src={SHADOW} alt="" />
            </Box>
        </>
    );
}

export function RightGradientBlue() {
    return (
        <>
            <Box sx={{
                position: 'absolute', right: '0', display:'none'
            }}>
                <img src={SHADOW} alt="" />
            </Box>
        </>
    );
}

export function LeftGradientWhite() {
    return (
        <>
            <Box sx={{
                position: 'absolute', left: '0',display:'none'
            }}>
                <img src={WHITE} alt="" />
            </Box>
        </>
    );
}

export function RightGradientWhite() {
    return (
        <>
            <Box sx={{
                position: 'absolute', right: '0',display:'none'
            }}>
                <img src={WHITE} alt="" />
            </Box>
        </>
    );
}