import React, { useState, useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
const Roadmap = () => {
    const [scroll, setScroll] = useState(false);
    const initRef = useRef();
    React.useEffect(() => {
        const handler = () => {
            if (
                window.pageYOffset >=
                initRef.current.offsetTop - window.innerHeight / 2
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
        };
    }, []);
    return (
        <div className="roadmap" id="roadmap">
            <div ref={initRef}>
                <Container maxWidth="lg" sx={{ margin: "100px auto" }}>
                    <Typography
                        textAlign="center"
                        fontSize="40px"
                        fontWeight="700"
                        lineHeight="53px"
                        color="inherit"
                    >
                        Roadmap
                    </Typography>
                    <Box mt="50px" textAlign="center">
                        <svg
                            width="102"
                            height="215"
                            viewBox="0 0 102 215"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="48"
                                width="6"
                                height="150"
                                rx="3"
                                fill={scroll ? "#0634DB" : "#D2D2D2"}
                            />
                            <g filter="url(#filter0_d_206_62)">
                                <circle
                                    cx="50.9524"
                                    cy="163.952"
                                    r="17.9524"
                                    fill={scroll ? "#0634DB" : "#D2D2D2"}
                                />
                            </g>
                            <circle cx="51" cy="164" r="11" fill="#fff" />
                            <defs>
                                <filter
                                    id="filter0_d_206_62"
                                    x="0"
                                    y="113"
                                    width="101.904"
                                    height="101.905"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="16.5" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_206_62"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_206_62"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                        <Box
                            // color="inherit" 
                            sx={{ width: { md: '50%', xs: '80%' } }}
                            margin="2px auto"
                        >
                            <Typography
                                textAlign="center"
                                fontSize="31px"
                                fontWeight="700"
                                lineHeight="42px"
                                color="inherit"
                            >
                                Mint Sale
                            </Typography>
                            <br />
                            <Typography
                                gutterBottom
                                textAlign="center"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="20px"
                                color="inherit"
                                fontFamily="Heebo"
                            >
                                On 10th March 2022 12:30 pm UTC, our NFT mint sale will begin. Each NFT will be sold at a price 0.1 ETH. On minting, users will randomly get an NFT out of our collection. On 15th March 2022, we will reveal all the unique NFT artworks with rarity.
                            </Typography>
                        </Box>
                    </Box>
                    <Two />
                    <Three />
                    <Four />
                    <Five/>
                </Container>
            </div>
        </div>
    );
};

export default Roadmap;

const Two = () => {
    const [scroll, setScroll] = useState(false);
    const twoRef = useRef();
    React.useEffect(() => {
        const handler = () => {
            if (
                window.pageYOffset >=
                twoRef.current.offsetTop - window.innerHeight / 2
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
        };
    }, []);

    console.log(scroll);
    return (
        <div ref={twoRef}>
            <Box mt="50px" textAlign="center">
                <svg
                    width="102"
                    height="215"
                    viewBox="0 0 102 215"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="48"
                        width="6"
                        height="150"
                        rx="3"
                        fill={scroll ? "#0634DB" : "#D2D2D2"}
                    />
                    <g filter="url(#filter0_d_206_62)">
                        <circle
                            cx="50.9524"
                            cy="163.952"
                            r="17.9524"
                            fill={scroll ? "#0634DB" : "#D2D2D2"}
                        />
                    </g>
                    <circle cx="51" cy="164" r="11" fill="#fff" />
                    <defs>
                        <filter
                            id="filter0_d_206_62"
                            x="0"
                            y="113"
                            width="101.904"
                            height="101.905"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="16.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_206_62"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_206_62"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
                <Box color="inherit" sx={{ width: { md: '50%', xs: '80%' } }} margin="2px auto">
                    <Typography
                        textAlign="center"
                        fontSize="31px"
                        fontWeight="700"
                        lineHeight="42px"
                        color="inherit"
                    >
                        Secret and private group
                    </Typography>
                    <br />
                    <Typography
                        gutterBottom
                        textAlign="center"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="20px"
                        color="inherit"
                        fontFamily="Heebo"
                    >
                        All our NFT buyers and holders will be eligible to be a part of a private group of like-minded crypto peoples where they can talk with some good crypto analysts.
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

const Three = () => {
    const [scroll, setScroll] = useState(false);
    const threeRef = useRef();
    React.useEffect(() => {
        const handler = () => {
            if (
                window.pageYOffset >=
                threeRef.current.offsetTop - window.innerHeight / 2
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
        };
    }, []);
    return (
        <>
            <div ref={threeRef}>
                <Box mt="50px" textAlign="center">
                    <svg
                        width="102"
                        height="215"
                        viewBox="0 0 102 215"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="48"
                            width="6"
                            height="150"
                            rx="3"
                            fill={scroll ? "#0634DB" : "#D2D2D2"}
                        />
                        <g filter="url(#filter0_d_206_62)">
                            <circle
                                cx="50.9524"
                                cy="163.952"
                                r="17.9524"
                                fill={scroll ? "#0634DB" : "#D2D2D2"}
                            />
                        </g>
                        <circle cx="51" cy="164" r="11" fill="#fff" />
                        <defs>
                            <filter
                                id="filter0_d_206_62"
                                x="0"
                                y="113"
                                width="101.904"
                                height="101.905"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="16.5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_206_62"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_206_62"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                    <Box color="inherit" sx={{ width: { md: '50%', xs: '80%' } }} margin="2px auto">
                        <Typography
                            textAlign="center"
                            fontSize="31px"
                            fontWeight="700"
                            lineHeight="42px"
                            color="inherit"
                        >
                            Social Investment opportunities
                        </Typography>
                        <br />
                        <Typography
                            gutterBottom
                            textAlign="center"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="20px"
                            color="inherit"
                            fontFamily="Heebo"
                        >
                            We will create an overall ecosystem where community can socially have some investment opportunities.
                        </Typography>
                    </Box>
                </Box>
            </div>
        </>

    );
};

const Four = () => {
    const [scroll, setScroll] = useState(false);
    const fourRef = useRef();
    React.useEffect(() => {
        const handler = () => {
            if (
                window.pageYOffset >=
                fourRef.current.offsetTop - window.innerHeight / 2
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
        };
    }, []);
    return (
        <>
            <div ref={fourRef}>
                <Box mt="50px" textAlign="center">
                    <svg
                        width="102"
                        height="215"
                        viewBox="0 0 102 215"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="48"
                            width="6"
                            height="150"
                            rx="3"
                            fill={scroll ? "#0634DB" : "#D2D2D2"}
                        />
                        <g filter="url(#filter0_d_206_62)">
                            <circle
                                cx="50.9524"
                                cy="163.952"
                                r="17.9524"
                                fill={scroll ? "#0634DB" : "#D2D2D2"}
                            />
                        </g>
                        <circle cx="51" cy="164" r="11" fill="#fff" />
                        <defs>
                            <filter
                                id="filter0_d_206_62"
                                x="0"
                                y="113"
                                width="101.904"
                                height="101.905"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="16.5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_206_62"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_206_62"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                    <Box color="inherit" sx={{ width: { md: '50%', xs: '80%' } }} margin="2px auto">
                        <Typography
                            textAlign="center"
                            fontSize="31px"
                            fontWeight="700"
                            lineHeight="42px"
                            color="inherit"
                        >
                            NFTs for governance
                        </Typography>
                        <br />
                        <Typography
                            gutterBottom
                            textAlign="center"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="20px"
                            color="inherit"
                            fontFamily="Heebo"
                        >
                            NFT holders can vote for their favourite features and we will try introducing it for the community.
                        </Typography>
                    </Box>
                </Box>
            </div>
        </>

    );
};

const Five = () => {
    const [scroll, setScroll] = useState(false);
    const fiveRef = useRef();
    React.useEffect(() => {
        const handler = () => {
            if (
                window.pageYOffset >=
                fiveRef.current.offsetTop - window.innerHeight / 2
            ) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
        };
    }, []);
    return (
        <>
            <div ref={fiveRef}>
                <Box mt="50px" textAlign="center">
                    <svg
                        width="102"
                        height="215"
                        viewBox="0 0 102 215"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="48"
                            width="6"
                            height="150"
                            rx="3"
                            fill={scroll ? "#0634DB" : "#D2D2D2"}
                        />
                        <g filter="url(#filter0_d_206_62)">
                            <circle
                                cx="50.9524"
                                cy="163.952"
                                r="17.9524"
                                fill={scroll ? "#0634DB" : "#D2D2D2"}
                            />
                        </g>
                        <circle cx="51" cy="164" r="11" fill="#fff" />
                        <defs>
                            <filter
                                id="filter0_d_206_62"
                                x="0"
                                y="113"
                                width="101.904"
                                height="101.905"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="16.5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_206_62"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_206_62"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                    </svg>
                    <Box color="inherit" sx={{ width: { md: '50%', xs: '80%' } }} margin="2px auto">
                        <Typography
                            textAlign="center"
                            fontSize="31px"
                            fontWeight="700"
                            lineHeight="42px"
                            color="inherit"
                        >
                            Metaverse Club
                        </Typography>
                        <br />
                        <Typography
                            gutterBottom
                            textAlign="center"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="20px"
                            color="inherit"
                            fontFamily="Heebo"
                        >
                            We plan to have special club on metaverse for all our NFT holders. Members can interact and party along in the metaverse space with the like-minded peoples.
                        </Typography>
                        <br />
                        <svg
                            width="102"
                            height="215"
                            viewBox="0 0 102 215"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="48"
                                width="6"
                                height="150"
                                rx="3"
                                fill={scroll ? "#0634DB" : "#D2D2D2"}
                            />
                            <g filter="url(#filter0_d_206_62)">
                                <circle
                                    cx="50.9524"
                                    cy="163.952"
                                    r="17.9524"
                                    fill={scroll ? "#0634DB" : "#D2D2D2"}
                                />
                            </g>
                            <circle cx="51" cy="164" r="11" fill="#fff" />
                            <defs>
                                <filter
                                    id="filter0_d_206_62"
                                    x="0"
                                    y="113"
                                    width="101.904"
                                    height="101.905"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="16.5" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0.0196078 0 0 0 0 0.184314 0 0 0 0 0.760784 0 0 0 1 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_206_62"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_206_62"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    </Box>
                </Box>
            </div>
        </>
    );
};
