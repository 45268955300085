import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Typography, Button, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Fade from 'react-reveal/Fade';

const Faqs = () => {
    return <>
        <Container maxWidth="md" sx={{ margin: '100px auto', display: 'none' }}>
            <Typography fontSize="38px" fontWeight="bold" textAlign="center" color="inherit" fontFamily="Heebo" lineHeight="56px">
                FAQs
            </Typography>
            <br /><br />
            {new Array(6).fill("").map((item, i) => (
                <Accordion
                    elevation={0}
                    sx={{
                        '&:before': {
                            display: 'none',
                        },
                        background: 'rgba(216, 216, 216, 0.6)', color: 'secondary.dark',
                        borderLeft: '2px solid #052FC2', borderRight: '2px solid #052FC2', margin: '15px 0', borderRadius: '22px'
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.dark' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontFamily="Heebo">What is NFT?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ borderTop: '1px solid #052FC2', paddingTop: '30px', }}>
                            nFT stands for ‘non-fugible token’.<br />
                            a NFT is basically data that is accounted for in a digital ledger, and data represents something specific and unique.
                            <br />A NFT can, for example, represent a unique piece of art or a game token.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
        <Box sx={{ padding: { md: '0', xs: '0 20px' } }}>
            <Fade bottom>
                <Container maxWidth="md"
                    sx={{
                        textAlign: 'center',
                        background: "rgba( 230, 230, 230, 0.25 )",
                        backdropFilter: "blur( 4px )",
                        WebkitBackdropFilter: "blur( 4px )",
                        border: "1px solid rgba( 255, 255, 255, 0.18 )"
                        , borderRadius: '20px', margin: '100px auto', padding: '50px'
                    }}>
                    <Typography gutterBottom fontSize="38px" fontWeight="bold" color="inherit" fontFamily="Heebo" lineHeight="56px">
                        Join Our Community
                    </Typography>
                    <Typography fontSize="20px" fontWeight="300" color="inherit" fontFamily="Heebo" lineHeight="29px">
                        Be a part of our public community and get all the latest updates
                    </Typography>
                    <br />
                    <a href="https://t.me/cryptoconvoy" target="_blank" rel="noreferrer " style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary" sx={{
                            background:
                                "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
                            boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
                            borderRadius: "20px", padding: { md: '10px 50px', xs: '10px 30px' }, fontSize: '18px', fontWeight: 'bold'
                        }}>
                            Join Our Telegram
                        </Button>
                    </a>
                </Container>
            </Fade>
        </Box>
    </>;
};

export default Faqs;
