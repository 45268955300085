import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import curDot from "cursor-dot";
import { useWallet, UseWalletProvider } from "use-wallet";
import config from "./config";
import { BrowserRouter } from "react-router-dom";

const $ = (s) => document.querySelector(s);

const cursor = curDot({
  easing: 4,
});

cursor.over(".title", {
  background: "#fff",
});

cursor.over(".react1", {
  borderColor: "rgba(255,255,255,.38)",
});

cursor.over(".react2", {
  scale: 0.5,
  background: "#fff",
});

cursor.over($(".react3"), {
  scale: 1.4,
  background: "#faa2c1",
  borderColor: "transparent",
});

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>

    <UseWalletProvider
      chainId={config.CHAIN_ID}
      connectors={{
        // This is how connectors get configured
        portis: { dAppId: "my-dapp-id-123-xyz" },
        walletconnect: { rpc: { 1: config.RPC } },
      }}
    >
      <App />
    </UseWalletProvider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
