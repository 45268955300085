import React from 'react';
import { Link } from "react-scroll";
import { Container, Typography, } from '@mui/material'
import DEARIMAGE from "../../Assets/dear.png"
import EXPLORE from "../../Assets/explore.png"
import FROGIMAGE from "../../Assets/frog.png"

const Home = () => {
  return <>
    <Container maxWidth="lg" sx={{ padding: '150px  100px 100px 100px', display: { md: 'block', xs: 'none' } }} >
      <Typography color="inherit" sx={{ fontSize: "86px", fontWeight: 'bold', lineHeight: '116px', textAlign: 'center' }}>
        Discover
        <img src={DEARIMAGE} alt="img_" style={{ margin: '10px 10px -30px 10px' }} />
        Collect <br />
        And Sell rare
        <img src={FROGIMAGE} alt="img_" style={{ margin: '10px 10px -30px 10px' }} /><br />
        <Link to="about" smooth={true} duration={1000}>
          <img src={EXPLORE} alt="img_" style={{ margin: '10px 50px -50px 0',cursor:'grab'}} />
        </Link>
        Crypto Convoys
      </Typography>
    </Container>
    <Container maxWidth="lg" sx={{ padding: '100px 50px 0 50px', display: { md: 'none', xs: 'block' } }} >
      <Typography color="inherit"  sx={{ fontSize: "50px", fontWeight: 'bold', lineHeight: '70px', textAlign: 'center' }}>
        Discover <img src={DEARIMAGE} alt="img_" width="70px" />
        Collect <br />
        And Sell rare
        <img src={FROGIMAGE} alt="img_" width="70px" style={{ margin: '10px 10px 0 10px' }} /><br />
        Crypto Convoys
      </Typography>
      <Link to="about" smooth={true} duration={1000}>
           <img src={EXPLORE} alt="img_" width="100px" />
        </Link>
    </Container>
  </>;
};

export default Home;
