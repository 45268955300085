import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, Button } from "@mui/material";
import Fade from "react-reveal/Fade";
import { styled } from "@mui/system";
import { useWallet } from "use-wallet";
import NFT_ABI from "../../../config/abi/nft.json";

import Web3 from "web3";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import config from "../../../config";
import { Web } from "@mui/icons-material";
import {
    Multicall,
    ContractCallResults,
    ContractCallContext,
  } from 'ethereum-multicall';


const Upper = styled("div")`
  @media only screen and (max-width: 768px) {
  }
`;

const MintingPanel = () => {
  const [readContract, setReadContract] = useState();

  const [noOfNFT, setNoOfNFT] = useState(1);
  const [mintPrice, setMintPrice] = useState("0");
  const [mintingCost, setMintingCost] = useState(0);
  const [myBalance, setMyBalance] = useState(0);
  const [myMint, setMyMint] = useState(0);
  const [isSoldOut, setSoldOut] = useState(false);
  const [isSaleActive, setSaleActive] = useState(false);
  const [maxPerWallet, setMaxPerWallet] = useState(0);

  const wallet = useWallet();

  useEffect(() => {
    wallet.connect();

    loadContract();
  }, []);

  useEffect(() => {
    loadContract();
    // setInterval(async () => {
    //   await loadContract();
    // }, 3000);
  }, [wallet, wallet.account]);
  const loadContract = async () => {
    const readWeb3 = new Web3(config.RPC);
    const _readContract = new readWeb3.eth.Contract(
      NFT_ABI,
      config.CONTRACT_ADDRESS
    );

    setReadContract(_readContract);
  };

  const loadNFTData = async () => {


    const _mintPrice = await readContract.methods.price().call();
    setMintPrice(_mintPrice);




    const _maxSupply = await readContract.methods.MAX_SUPPLY().call();
    const _totalSupply = await readContract.methods.totalSupply().call();
    setSoldOut(_maxSupply === _totalSupply);

    const _saleIsActive = await readContract.methods.saleIsActive().call();
    setSaleActive(_saleIsActive);
    const _maxNFTPerWallet = await readContract.methods
      .maxNFTPerWallet()
      .call();

    setMaxPerWallet(_maxNFTPerWallet);

    if (wallet.account) {
      const _myMints = await readContract.methods
        .mintedRecord(wallet.account)
        .call();

      setMyMint(_myMints);
      const _myBalance = await readContract.methods
        .balanceOf(wallet.account)
        .call();

      setMyBalance(_myBalance);
    }
  };

  useEffect(() => {
    if (readContract) {
      loadNFTData();
    }
  }, [readContract]);
  useEffect(() => {
    setMintingCost(mintPrice * noOfNFT);
  }, [noOfNFT, mintPrice]);

  const handleMint = async () => {
    try {
      const web3 = new Web3(wallet.ethereum);
      const contract = new web3.eth.Contract(NFT_ABI, config.CONTRACT_ADDRESS);
      await contract.methods.publicMint(noOfNFT).send({
        from: wallet.account,
        value: mintingCost.toString(),
      });
    } catch (err) {
        console.error("errrrrr",err)
    }
  };

  const renderButton = () => {
    if (!wallet.account) {
      return (
        <Button
          variant="contained"
          mt="20px"
          onClick={() => {
            if(window.ethereum){
                wallet.connect();
            }else{
                wallet.connect("walletconnect");
            }
          }}
          style={{ marginTop: 10 }}
          color="primary"
          sx={{
            background:
              "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
            boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
            borderRadius: "20px",
            padding: "10px 50px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Connect Wallet
        </Button>
      );
    }
    if (!isSaleActive) {
      return (
        <Button
          variant="contained"
          mt="20px"
          style={{ marginTop: 10 }}
          color="primary"
          sx={{
            background:
              "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
            boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
            borderRadius: "20px",
            padding: "10px 50px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Sale Not Active
        </Button>
      );
    }

    if (isSoldOut) {
      return (
        <Button
          variant="contained"
          mt="20px"
          style={{ marginTop: 10 }}
          color="primary"
          sx={{
            background:
              "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
            boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
            borderRadius: "20px",
            padding: "10px 50px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Sold Out
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        onClick={() => {
          handleMint();
        }}
        mt="20px"
        style={{ marginTop: 10 }}
        color="primary"
        sx={{
          background:
            "radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #052FC2",
          boxShadow: "4px 38px 62px rgba(0, 0, 0, 0.5)",
          borderRadius: "20px",
          padding: "10px 50px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Mint Now
      </Button>
    );
  };

  const getShortWallet = () => {
    return `${wallet.account.substring(0, 8)}....${wallet.account.substring(
      wallet.account.length - 8,
      wallet.account.length
    )}`;
  };

  return (
    <>
      <div
        className="col"
        style={{
          display: "list-item",
          textAlign: "center",
          // width:"100%",
          background: "#4558ad66",
          padding: 20,
          borderRadius: 20,
          justifyContent: "center",
        }}
      >
        {wallet.account ? (
          <Grid container>
            <Grid item xs={5} xl={5} alignSelf="start">
              <Typography
                fontSize="15px"
                fontWeight={"bolder"}
                textAlign={"start"}
              >
                Account:
                <br /> {getShortWallet()}
              </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5} xl={5} alignSelf="end">
              <Typography
                fontSize="15px"
                fontWeight={"bolder"}
                textAlign={"start"}
              >
                Balance:
                <br /> {(wallet.balance / 1e18).toFixed(2)} ETH
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Upper>
          <FaMinusCircle
            size="1.4rem"
            className="iconL"
            style={{ color: "#474e6f", cursor: "pointer" }}
            onClick={() => {
              if (noOfNFT > 1) {
                setNoOfNFT(noOfNFT - 1);
              }
            }}
          />

          <input
            placeholder="NFT"
            value={noOfNFT}
            style={{
              width: "12rem",
              marginLeft: 10,
              marginRight: 10,
              height: "3rem",
              borderRadius: "0.5rem",
              border: "none",
              fontSize: "1.5rem",
              paddingLeft: "0.5rem",
            }}
            onChange={(e) => {
              setNoOfNFT(parseInt(e.target.value));
            }}
            type="number"
          />

          <FaPlusCircle
            size="1.4rem"
            className="iconR"
            style={{ color: "#474e6f", cursor: "pointer" }}
            onClick={() => {
              const totalNFT = parseInt(noOfNFT) + 1;
              if (noOfNFT < maxPerWallet) {
                setNoOfNFT(totalNFT);
              }
            }}
          />
        </Upper>

        {renderButton()}
        <Grid container spacing={2} mt="10px">
          <Grid item xs={1} />

          <Grid item xs={5}>
            <div>
              <Typography fontSize="20px">Minting Cost</Typography>

              <Typography
                fontSize="30px"
                fontWeight="bolder"
                lineHeight="53px"
                color="#474e6e"
              >
                {(mintingCost / 1e18).toFixed(2)} ETH
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>
              {" "}
              <Typography fontSize="20px">Price Per NFT</Typography>
              <Typography
                fontSize="30px"
                fontWeight="bolder"
                lineHeight="53px"
                color="#474e6e"
              >
                {(mintPrice / 1e18).toFixed(2)} ETH
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} />
        </Grid>

        {wallet.account ? (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Typography fontSize="20px">You Own</Typography>

            <Typography
              fontSize="30px"
              fontWeight="bolder"
              lineHeight="53px"
              color="#474e6e"
            >
              {myBalance} NFTs
            </Typography>
          </div>
        ) : null}
      </div>
    </>
  );
};

const Mint = () => {
  return (
    <div>
      <Container
        id="utilites"
        maxWidth="lg"
        sx={{ margin: "100px auto", textAlign: { md: "left", xs: "center" } }}
      >
        <Grid
          container
          spacing={1}
          style={{ marginTop: 100 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="stretch"
          flexWrap="wrap-reverse"
        >
          <Grid item xl="5" md="5" xs="12">
            <Fade bottom>
              <Typography
                fontSize="40px"
                fontWeight="700"
                lineHeight="53px"
                color="inherit"
              >
                Mint NFTs
              </Typography>
              <br />
              <br />
              <Typography
                fontSize="20px"
                fontWeight="400"
                fontFamily="Heebo"
                lineHeight="29px"
                color="inherit"
              >
                By collecting our NFTs, holders will be eligible to be a part of
                a private group of like-minded peoples, where they can talk with
                some good crypto analysts, get a lot of social investment
                opportunities and get into our metaverse club. Other than this
                we will reveal a lot of new features and exclusive rewards for
                our NFT holders.
              </Typography>
              <br />
              <br />

              <br />
            </Fade>
          </Grid>

          <Grid item xl="5" md="5" xs="12">
            <Fade left>
              

              <MintingPanel />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mint;
