import * as React from "react";
import "./App.css";
// import theme from './Views/Theme/theme';
import Home from "./Views/Sections/Home/Home";
import Header from "./Views/Components/Header";
import Footer from "./Views/Components/Footer";
import { Routes, Route, Link } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Mint from "./Views/Sections/Mint/Mint";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Cinzel",
        },
        palette: {
          mode,
          primary: {
            main: "#052FC2",
            dark: "#0D0D0D",
          },
          secondary: {
            main: "#1B1B1B",
            light: "#F9F8F7",
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        overrides: {
          MuiContainer: {
            root: {
              maxWidth: "90% !important",
            },
          },
          MuiInputBase: {
            root: {
              fontSize: "0.95rem",
              "& input": {
                padding: "14.5px 15px",
              },
            },
          },
          MuiOutlinedInput: {
            notchedOutline: {
              borderColor: "red",
            },
            multiline: {
              padding: "14.5px 15px",
            },
          },
          MuiInputLabel: {
            outlined: {
              fontSize: "0.95rem",
              transform: "translate(15px, 15px) scale(1)",
            },
          },

          MuiSelect: {
            root: {
              padding: "14.5px 15px",
            },
          },

          MuiAutocomplete: {
            popper: {
              fontSize: "2rem",
            },
          },

          MuiPaper: {
            elevation1: {
              borderRadius: 0,
              boxShadow: "0px 4px 24px rgba(34, 41, 47, 0.1)",
            },
          },
          MuiListItemIcon: {
            root: {
              minWidth: "2.25rem",
              "& svg": {
                fontSize: "1.35rem",
              },
              "& img": {
                width: "1.35rem",
                height: "1.35rem",
              },
            },
          },
          MuiTypography: {
            h1: {
              fontSize: "2rem",
              fontWeight: 700,
            },

            h2: {
              fontSize: "1.85rem",
              fontWeight: 600,
            },

            h3: {
              fontSize: "1.1rem",
              fontWeight: 600,
              letterSpacing: 0.8,
            },

            h4: {
              fontSize: "1.05rem",
              fontWeight: 700,
              letterSpacing: 0.2,
            },

            h5: {
              fontSize: "1.05rem",
              fontWeight: 500,
            },

            h6: {
              fontSize: "1rem",
              fontWeight: 500,
            },

            body1: {
              fontSize: "0.95rem",
              fontWeight: 500,
              letterSpacing: 0.2,
              wordSpacing: 1,
              lineHeight: 1.7,
            },

            body2: {
              fontSize: "0.875rem",
              fontWeight: 500,
              letterSpacing: 0.2,
              wordSpacing: 1,
              lineHeight: 1.7,
            },
          },
        },
      }),
    [mode]
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          className="main"
          sx={{
            bgcolor: "background.default",
            color: "text.primary",
          }}
        >
          <Header btn={<ToggleBtn />} />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/mint"
              element={
                <div style={{ marginTop: 40, height: "100vh" }}>
                  <Mint />
                </div>
              }
            />
          </Routes>

          <Footer />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

function ToggleBtn() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <IconButton
      onClick={colorMode.toggleColorMode}
      color="inherit"
      sx={{
        ml: 2,
        // position:'fixed',right:'20px',top:'30px'
      }}
    >
      {theme.palette.mode === "dark" ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  );
}
