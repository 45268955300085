import * as React from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import {
  AppBar,
  Toolbar,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
  Box,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Link } from "react-scroll";
import CONVOY from "../Assets/Crypto Convoy.png";

const useStyles = makeStyles((theme) => ({
  hamburger: {
    color: "white",
    marginLeft: "auto",
  },
  sidedrawer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "3rem",
    width: "100%",
    height: "78vh",
    bgcolor: "background.default",
    // background: "#fff",
    position: "relative",
  },
  closeIcon: {
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    // color: "#000",
    color: "text.primary",
    position: "absolute",
    right: "0",
    top: "0",
  },
  navLinks: {
    fontFamily: "Heebo",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    // color: "#0D0D0D",
    cursor: "pointer",
    // "&:hover": {
    //   // color: "#052FC2",
    //   borderBottom: "5px solid #052FC2",
    // },
  },
}));

export default function Header({ btn }) {
  const classes = useStyles();
  const theme = useTheme();
  const color = theme.palette.mode;
  const trigger = useScrollTrigger();

  // const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down(1075));

  const [open, setOpen] = useState(false);
  const handleDrawer = (bool) => () => {
    setOpen(bool);
  };

  return (
    <>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          marginTop: trigger ? "15px" : null,
          background: "transparent",
          boxShadow: "none",
          padding: "20px 10px",
          transition: "1s ease",
        }}
      >
        <div style={{ position: "relative" }}></div>
        <Toolbar
          elevation={trigger ? 24 : 0}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba( 255, 255, 255, 0.25 )",
            backdropFilter: "blur( 4px )",
            WebkitBackdropFilter: "blur( 4px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
          }}
        >
          <Box flexGrow="1" sx={{ paddingLeft: { md: "35px", xs: "0" } }}>
            <a 
                          style={{textDecoration:"none", color:"black"}}

            href="/" smooth={true} duration={1000}>
              <img src={CONVOY} alt="" style={{ width: "180px" }} />
            </a>
          </Box>
          {!isMobile && (
            <Toolbar>
              <a 
                            style={{textDecoration:"none", color:"black"}}

              href="/#about" smooth={true} duration={1000}>
                <Typography
                  className={classes.navLinks}
                  fontFamily="Heebo"
                  color="inherit"
                  sx={{ mr: 5 }}
                >
                  <b>About</b>
                </Typography>
              </a>
              <a 
                            style={{textDecoration:"none", color:"black"}}

              href="/#/utilites" smooth={true} duration={1000}>
                <Typography
                  className={classes.navLinks}
                  fontFamily="Heebo"
                  color="inherit"
                  sx={{ mr: 5 }}
                >
                  <b>Utilites</b>
                </Typography>
              </a>
              <a 
                            style={{textDecoration:"none", color:"black"}}

              href="/#roadmap" smooth={true} duration={1000}>
                <Typography
                  className={classes.navLinks}
                  fontFamily="Heebo"
                  sx={{ mr: 5 }}
                >
                  <b>Roadmap</b>
                </Typography>
              </a>
              <a href="/#commnunity" 
              style={{textDecoration:"none", color:"black"}}
              smooth={true} duration={2000}>
                <Typography
                  className={classes.navLinks}
                  fontFamily="Heebo"
                  sx={{ mr: 5 }}
                >
                  <b>Community</b>
                </Typography>
              </a>

              <a href="/mint" 
                            style={{textDecoration:"none", color:"black"}}

              smooth={true} duration={2000}>
                <Typography
                  className={classes.navLinks}
                  fontFamily="Heebo"
                  sx={{ mr: 5 }}
                >
                  <b>Mint Now</b>
                </Typography>
              </a>

              {/* <Button variant="contained" size="small" color="primary" sx={{ borderRadius: '34px', padding: '7px 20px', }}>
                Connect Wallet
              </Button> */}
              {btn}
            </Toolbar>
          )}
          {isMobile && (
            <IconButton
              className={classes.hamburger}
              onClick={handleDrawer(true)}
            >
              <MenuOpenIcon sx={{ color: "#000" }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="bottom"
        onClose={handleDrawer(false)}
        open={isMobile && open}
        sx={{
          borderRadius: "20px 20px 0 0",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.default",
            color: "text.primary",
            padding: "30px",
            borderRadius: "20px 20px 0 0",
          }}
          // sx={{ background: '#fff', padding: '30px' }}
        >
          <Box
            className={classes.sidedrawer}
            sx={{ borderRadius: "20px 20px 0 0" }}
          >
            <CloseFullscreenIcon
              className={classes.closeIcon}
              onClick={handleDrawer(false)}
            />
            <a
              href="/#about"
              smooth={true}
              duration={1000}
              style={{textDecoration:"none", color:"black"}}

              onClick={handleDrawer(false)}
            >
              <Typography className={classes.navLinks} fontFamily="Heebo">
                <b>About</b>
              </Typography>
            </a>
            <a
              href="/#utilites"
              smooth={true}
              style={{textDecoration:"none", color:"black"}}

              duration={1000}
              onClick={handleDrawer(false)}
            >
              <Typography className={classes.navLinks} fontFamily="Heebo">
                <b>Utilites</b>
              </Typography>
            </a>
            <a
              href="/#roadmap"
              smooth={true}
              duration={1000}
              style={{textDecoration:"none", color:"black"}}

              onClick={handleDrawer(false)}
            >
              <Typography className={classes.navLinks} fontFamily="Heebo">
                <b>Roadmap</b>
              </Typography>
            </a>
            <a
              href="/#commnunity"
              smooth={true}
              duration={2000}
              style={{textDecoration:"none", color:"black"}}

              onClick={handleDrawer(false)}
            >
              <Typography className={classes.navLinks} fontFamily="Heebo">
                <b>Community</b>
              </Typography>
            </a>

            <a
              href="/mint"
              smooth={true}
              duration={2000}
              style={{textDecoration:"none", color:"black"}}

              onClick={handleDrawer(false)}
            >
              <Typography className={classes.navLinks} fontFamily="Heebo">
                <b>Mint Now</b>
              </Typography>
            </a>
            {/* <Button variant="contained" size="small" color="primary" sx={{ borderRadius: '34px', padding: '7px 20px', }}>
              Connect Wallet
            </Button> */}
            {btn}
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
