import React from 'react'
import { makeStyles } from '@mui/styles';
import { Typography, Box, Container, } from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import CONVOY from "../Assets/Crypto Convoy.png"
import GROUP_1255 from "../Assets/Group 1255.png"
  
import GROUP_1254 from "../Assets/Group 1254.png"

const useStyles = makeStyles((theme) => ({
    Footer: {
        background: "rgba( 230, 230, 230, 0.25 )",
        backdropFilter: "blur( 4px )",
        WebkitBackdropFilter: "blur( 4px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        width: '100%',
        height: 'auto',
        color: 'text.primary',
        padding: '10px  0',
        borderRadius: '20px 20px 0 0',
        textAlign: 'center'
    },
}));


const Footer = () => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.mode;
    return (
        < >
            <section id="Footer" className={classes.Footer}  >
                <img src={CONVOY} alt="" style={{ width: '180px' }} />
            </section>
            <Box sx={{
                width: '100%', background: "rgba( 230, 230, 230, 0.25 )",
                backdropFilter: "blur( 4px )",
                WebkitBackdropFilter: "blur( 4px )",
                border: "1px solid rgba( 255, 255, 255, 0.18 )", padding: '30px 0px', textAlign: 'center',
            }}>
                <Container maxWidth="lg" sx={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: { md: 'space-between', xs: 'center' }, flexWrap: 'wrap-reverse' }} >
                    <Typography variant="h3" color="inherit" sx={{ fontSize: '12px' }}>
                        © 2022 Crypto Convoy. All rights reserved.
                    </Typography>
                    <Box display="flex" gap="20px">
                        <a href="https://t.me/cryptoconvoy" target="_blank" rel="noreferrer">
                            <img src={GROUP_1254} alt="" />
                        </a>
                        <a href="https://www.twitter.com/crypto_convoy" target="_blank" rel="noreferrer">
                            <img src={GROUP_1255} alt="" />
                        </a>
                    </Box>
                </Container>
            </Box>
        </ >
    )
}

export default Footer
